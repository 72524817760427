#tsparticles{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.landingPage {
  overflow: hidden;
  overflow-y: hidden;
}